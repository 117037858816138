import React from 'react'
import {Button} from 'react-bootstrap'
import {useContext} from 'react';
import {AppContext} from '../../context.js'
import { useHistory } from 'react-router';
import {useParams} from 'react-router-dom';


export default function AddressSwitch() {

    const {state, dispatch} = useContext(AppContext)
    const history = useHistory();
    let { id } = useParams();

    const switchLocation = (city) => {
        console.log(city)
        let mesto_url = city.mesto.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');
        dispatch({ type: 'LOADLOCATION', payload: { source:"AddressSwitch",...city, mesto_url: mesto_url, adresa: state.location.adresa,polygonRestaurants:state.location.polygonRestaurants, multiLocation: state.location.multiLocation, multiLocationWarningShown:true  } })
        history.push(`/rozvoz-jidel/${mesto_url}`);
    }

    return (
        <>
        
			<h6 className="mt-3">Zvolená oblast rozvozu:</h6>

            {state?.location?.multiLocation?.map(city=>(
                <Button onClick={()=>{city!==id && switchLocation(city)}} variant={state.location.mesto == city.mesto ? "primary":"secondary"} className="mr-3">{city.mesto}</Button>
            ))}
			 
        
        </>
    )
}

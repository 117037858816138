import React, { useState, useContext, useEffect, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { Button, Form, Row, Col, FormControl, InputGroup, ButtonGroup, Spinner, Image } from 'react-bootstrap';
import { AppContext } from '../../context.js'
import useFetch from '../../useFetch.js'
import google_analytics from '../../models/google_analytics'

import moment from 'moment';
import ExtraDrinks from './ExtraDrinks';
import Icofont from 'react-icofont';
import { NavLink, Link } from 'react-router-dom';
import ChooseAddressCard from '../common/ChooseAddressCard';
import PaymentMethod from './PaymentMethod';
import QuickBite from '../common/QuickBite';
import AddAddressModal from '../modals/AddAddressModal';
import axios from 'axios'

import AddressSearch from '../AddressSearch';
import OrderForm from './OrderForm.js';
import { useForm } from "react-hook-form";
import { round } from 'lodash';
import { Helmet } from 'react-helmet';
import ExtraItems from './ExtraItems.js';
import { MinimalOrderAdditionModal } from '../modals/MinimalOrderAdditionModal.js';
import CartRecapitulationModal from './CartRecapitulationModal.js';




const NewCheckout = (props) => {

    const [disableCardPayments, setDisableCardPayments] = useState(false)

    let paymentMethods = {
        "cash": "Platba v hotovosti",
        "credit-card": "Platba kreditní kartou",
        "meal-voucher": "Platba stravenkami",
        "credit-card-driver": "Kartou u řidiče",
        "twisto": "Twisto",
        "mallpay": "Mallpay",
        "credits": "Použít kredity"
    }

    const { state, dispatch } = useContext(AppContext)

    let { id } = useParams(); //mesto

    const formHook = useForm(); //{ register, handleSubmit, setFocus, watch, formState: { errors } }

    const { data: credits, loading: loadingCredits, error: errorCredits } = useFetch('/api/user/credits/' + props.restaurant)
    const { data: eshop, loading: loadingEshop, error: errorEshop } = useFetch('/api/restaurants/jpnEshop/' + props.restaurant)


    const [eshopExpanded, setEshopExpanded] = useState(false)
    const [showExtraItems, setShowExtraItems] = useState(false)
    const [addedItem, setaddedItem] = useState(null)

    const displayExtraItems = (item) => {
        setaddedItem(item)
        setShowExtraItems(true)
    };

    /* Inputs */
    const [noteToChef, setnoteToChef] = useState("")
    const [noteToCourier, setnoteToCourier] = useState("")

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [overrideInfo, setOverrideInfo] = useState(false)
    const [beginCheckoutSended, setBeginCheckoutSended] = useState(false)
    const [marketingAgreement , setMarketingAgreement] = useState(false)

    const [showCartRecapitulationModal, setShowCartRecapitulationModal] = useState(false)

    useEffect(() => {
        google_analytics.begin_checkout(state.cart, state.activeRestaurant)
    }, [])


    useEffect(() => {
        const { user } = state
        setFirstname(user.jmeno || "")
        setLastname(user.prijmeni || "")
        setPhone(user.telefon || "")
        setEmail(user.login || "")
    }, [state.user])


    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(localStorage.getItem('preferedPaymentMethod') || "cash")

    useEffect(() => {
        if (selectedPaymentMethod != "credit-card") {
            setTipCourier(0)
            dispatch({ type: 'SET_TOTALADDITION', payload: { tipCourier: 0, creditsUsed: Number(creditsUsed) } })
        }

        localStorage.setItem('preferedPaymentMethod', selectedPaymentMethod);
        google_analytics.add_payment_info(state.cart, state.activeRestaurant, selectedPaymentMethod, props.totalPrice)
        setBeginCheckoutSended(true)
    }, [selectedPaymentMethod])

    const [creditsUsed, setCreditsUsed] = useState(0)
    const [tipCourier, setTipCourier] = useState(0)

    //Search
    const [resultSelected, setResultSelected] = useState(false)
    const [searchError, setSearchError] = useState("")


    const handleAddItemEshop = (item) => {
        // console.log(item)
        //item.quantity = 1
        setShowExtraItems(false)
        setaddedItem(null)
        dispatch({ type: 'ADD_TO_CART', payload: item })
    }

    /* Toggle state  */
    const [openSection1, setOpenSection1] = useState(!props.isOpen);
    const [openSection2, setOpenSection2] = useState(false);
    const [openSection3, setOpenSection3] = useState(true);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [toggleCredits, setToggleCredits] = useState(false)

    const hideAddressModal = () => {
        setShowAddressModal(false);
    }

    const [deliveryTimesSelected, setDeliveryTimesSelected] = useState(null)
    const [deliveryTimes, setDeliveryTimes] = useState([])
    const [deliveryDays, setDeliveryDays] = useState([])
    const [deliveryDaysSelected, setDeliveryDaysSelected] = useState(null)
    const [neverOpen, setNeverOpen] = useState(false)
    const [poledniMenuPolozkaNaPozdejiError, setPoledniMenuPolozkaNaPozdejiError] = useState(false)


    const checkLocationPolygon = () => {
        if (state.location && state.location.adresa)
            axios.get(`https://api.jidlopodnos.cz/api.php/gpsmulti/${state.location.adresa.gps_x}/${state.location.adresa.gps_y}`).then(response => {
                // console.log(response)
                if (response.status == 200) {
                    if (!!response.data?.find(item => item.code == "error") && !response.data?.find(item => item.mesto == state.activeRestaurant.city)) {
                        dispatch({ type: 'REMOVELOCATION' })
                        setSearchError(response.data.message)
                    }
                } else {
                    console.log("chyba serveru při checkLocatoinPolygon, request failed")
                }
            })
    }

    useEffect(() => { //LOCALSTORAGE OPERATION: DEFAULT PAYMENT METHOD & SET ISINCHECKOUT

        // (async function () {
        //     let checkResult = await checkLsValidity({
        //         cart: state.cart || [],
        //         activeRestaurant: state.activeRestaurant,
        //         location: state.location
        //     })
        //     checkResult.location=null

        //     dispatch({ type: 'COMPARE_LOCALSTORAGE', payload: checkResult })
        // })()


        let preferedPayment
        if (preferedPayment = localStorage.getItem('preferedPaymentMethod'))
            setSelectedPaymentMethod(preferedPayment)

        checkLocationPolygon()
        props.setIsInCheckout(true)
        return () => {
            props.setIsInCheckout(false)
        }
    }, [])

    useEffect(() => { //SET DELIVERY DAY
        if (state.activeRestaurant) {

            let days = []

            let deliveryMargin = 30 + state.activeRestaurant?.delivery_tmin + (Number(state?.location?.cas || 0))
            let moment_startHour = moment()
            let moment_endHour = moment(state.activeRestaurant?.delivery_to, "HH:mm")

            if (!state.isLunch) {
                moment_startHour.add(deliveryMargin, "minutes")
            }

            if ((moment_startHour < moment_endHour) && !state.deliveryLaterFrom) {
                days.push("Dnes")
                setDeliveryDaysSelected("Dnes")
            } else if (state.isLunch && state.deliveryLaterFrom) {
                setDeliveryDaysSelected("poledniMenuPolozkaNaPozdejiError")
            }
            else if (state.activeRestaurant.delivery_from_d1 && !state.isLunch) {
                setDeliveryDaysSelected(moment().add(1, 'days').format('D.M.'))
            } else if (state.activeRestaurant.delivery_from_d2 && !state.isLunch) {
                setDeliveryDaysSelected(moment().add(2, 'days').format('D.M.'))
            } else {
                setDeliveryDaysSelected("zavřeno")
            }


            if (state.activeRestaurant.delivery_from_d1 && !state.isLunch) days.push(moment().add(1, 'days').format('D.M.'))
            if (state.activeRestaurant.delivery_from_d2 && !state.isLunch) days.push(moment().add(2, 'days').format('D.M.'))
            setDeliveryDays(days)


        }
    }, [state.activeRestaurant])


    useEffect(() => { //SET DELIVERY TIMES
        if (state.activeRestaurant) {
            console.log("Calling setDeliveryTimes with day selected: ", deliveryDaysSelected)
            setDeliveryTimes(getDeliveryTimes(deliveryDaysSelected))
        }
    }, [deliveryDaysSelected, state.activeRestaurant])



    const getDeliveryTimes = (dSelected) => {

        if (dSelected == "zavřeno") {
            setNeverOpen(true)
            return [null]
        }

        if (dSelected == "poledniMenuPolozkaNaPozdejiError") {
            setPoledniMenuPolozkaNaPozdejiError(true)
            return [null]
        }


        if (!dSelected || !state.activeRestaurant) {
            return [null]
        }

        let moment_now = moment() //"14:30","HH:mm"

        let deliveryMargin = 30 + state.activeRestaurant?.delivery_tmin + (Number(state?.location?.cas || 0))

        if (props?.cityUtility == 100) {
            deliveryMargin += 60
        }

        let startHour
        let endHour
        let delivery_from
        if (!state.isLunch)
            switch (dSelected) {
                case "Dnes":
                    delivery_from = moment(state.activeRestaurant?.delivery_from, "HH:mm")
                    startHour = moment_now > delivery_from ? moment_now : delivery_from
                    console.log("Starting interval hour is: ", startHour.format('k.m'))
                    console.log("Delivery margin is:", deliveryMargin)
                    startHour = startHour.add(deliveryMargin, "minutes")


                    console.log("Starthour today with margin added: ", startHour.format("HH:mm"))
                    startHour = round_time(startHour, 30, "ceil")
                    //console.log("Starthour rouned is: ",startHour)

                    //startHour = round_time(startHour,60,"round").format('k.m')
                    endHour = moment(state.activeRestaurant.delivery_to, "HH:mm")
                    console.log("DELIVERY to:", state.activeRestaurant?.delivery_to)
                    console.log("Endhour today: ", endHour.format("HH:mm"))
                    break;
                case moment().add(1, 'days').format('D.M.'): //Zitra
                    //startHour = Number(state.activeRestaurant.delivery_from_d1?.split(":")[0]) + deliveryMargin
                    startHour = moment(state.activeRestaurant?.delivery_from_d1, "HH:mm")
                    if (state.deliveryLaterFrom) {
                        startHour = moment(state.deliveryLaterFrom, "HH:mm")
                        endHour = moment(state.activeRestaurant?.delivery_to_d1, "HH:mm")
                        break;
                    }
                    startHour = startHour.add(deliveryMargin, "minutes")

                    console.log("Starthour with margin added: ", startHour)
                    startHour = round_time(startHour, 30, "ceil")
                    console.log("Starthour rouned is: ", startHour)

                    endHour = moment(state.activeRestaurant?.delivery_to_d1, "HH:mm")
                    break;
                case moment().add(2, 'days').format('D.M.'): //Pozítří
                    //startHour = Number(state.activeRestaurant.delivery_from_d2?.split(":")[0]) + deliveryMargin
                    startHour = moment(state.activeRestaurant?.delivery_from_d2, "HH:mm")
                    if (state.deliveryLaterFrom) {
                        startHour = moment(state.deliveryLaterFrom, "HH:mm")
                        endHour = moment(state.activeRestaurant?.delivery_to_d2, "HH:mm")
                        break;
                    }
                    startHour = startHour.add(deliveryMargin, "minutes")

                    console.log("Starthour with margin added: ", startHour)
                    startHour = round_time(startHour, 30, "ceil")
                    console.log("Starthour rouned is: ", startHour)
                    endHour = moment(state.activeRestaurant?.delivery_to_d2, "HH:mm")
                    break;
            }
        else { //isLunch

            let lunch_from_today = moment(state.activeRestaurant?.lunch_from, "HH:mm")
            let open_from_today = moment(state.activeRestaurant?.delivery_from, "HH:mm")
            if (open_from_today > lunch_from_today) {
                lunch_from_today = open_from_today
            }
            console.log("Delivery margin is:", deliveryMargin)

            startHour = Number(moment_now.format('k.m')) > Number(lunch_from_today.format('k.m')) ? round_time(moment_now, 60, "ceil") : lunch_from_today
            endHour = moment(state.activeRestaurant?.lunch_to, "HH:mm")
            endHour.add(1, "hours")

            return generateTimeSeries(startHour, endHour, 60, true)

        }
        //const startHour = deliveryDaysSelected == "Dnes" ? Number(moment().format('k'))+1 : state.activeRestaurant.delivery_from.split(":")[0] 
        //if (state.isLunch) endHour -= 1

        return generateTimeSeries(startHour, endHour, 30)
    }





    useEffect(() => { //SET CHECKOUT INFO
        var d = new Date();
        var n = d.getFullYear();
        let daySelected = deliveryDaysSelected == "Dnes" || deliveryDaysSelected == null ? moment().format('D.M.') : deliveryDaysSelected
        let timeSelected = state.isLunch ? deliveryTimesSelected?.split("-")[0] : deliveryTimesSelected
        let dateComputed = d.getFullYear() + " " + daySelected + " " + timeSelected


        props.setCheckoutInfo({
            paymentMethod: selectedPaymentMethod,
            noteToChef,
            noteToCourier,
            creditsUsed: Number(creditsUsed) <= Number(credits?.credits_rest) + Number(credits?.credits_jpn) ? Number(creditsUsed) : Number(credits?.credits_rest) + Number(credits?.credits_jpn), //Credits used(input) or max credits available
            deliveryTime: deliveryTimesSelected ? moment(dateComputed, 'YYYY-DD-MM HH:mm').format("YYYY-MM-DD HH:mm") : null,
            deliveryTimesAvailable: deliveryTimes,
            marketingAgreement: marketingAgreement,
            handleSubmit: formHook.handleSubmit,
            user: {
                jmeno: firstname || state?.user?.firstname,
                prijmeni: lastname || state?.user?.lastname || "",
                login: email || state?.user?.login,
                telefon: phone || state?.user?.phone
            }
        })

    }, [selectedPaymentMethod, noteToCourier, noteToChef, firstname, lastname, email, phone, openSection2, state.user, deliveryTimesSelected, deliveryDaysSelected,marketingAgreement])


    useEffect(() => {
        dispatch({ type: 'SET_TOTALADDITION', payload: { tipCourier: Number(tipCourier), creditsUsed: state.totalAddition.creditsUsed } })
    }, [tipCourier])

    const useCredits = () => dispatch({ type: 'SET_TOTALADDITION', payload: { tipCourier: state.totalAddition.tipCourier, creditsUsed: Number(creditsUsed) } })

    const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    const removeLocation = () => {
        dispatch({ type: 'REMOVELOCATION' })
    }

    const userHasCompleInfo = useMemo(() => {
        const { user } = state
        if (user && user.telefon && user.login && user.jmeno) {
            return true
        }
        return false
    }, [state.user])

    useEffect(() => {
        console.log("deliverytimes: ", deliveryTimes)
        if (state.activeRestaurant && (!deliveryTimesSelected && (!props.isOpen || state.deliveryLaterFrom || (state.isLunch && !state.activeRestaurant.allowLunchNow)))) {
            let times = getDeliveryTimes(deliveryDaysSelected || "Dnes")
            console.log("Selecting default time, available times: ", times)
            setDeliveryTimesSelected(times[0])
        }

    }, [props.isOpen, props.isInCheckout, deliveryTimes])

    const handleSetCredits = (value) => {
        let totalCredits = Number(credits?.credits_jpn || 0) + Number(credits?.credits_rest || 0);

        if (value > state.total.totalPrice + state.totalAddition.creditsUsed) {
            console.log("OVERRIDE")
            value = state.total.totalPrice + state.totalAddition.creditsUsed;
        }
        (value > totalCredits) ? setCreditsUsed(totalCredits) : setCreditsUsed(value)
    }

    const disableSubmit = () => {
        if (!state.cart.length > 0 || props.orderSubmiting || !state.location) {
            return true
        }
        if (!state.user && (!firstname || !email || !phone)) {
            return true
        }
        let foodPrice = state.total.totalPrice + state.totalAddition.creditsUsed - state.total.deliveryPrice
        if (!state.cart.length > 0 || props.orderSubmiting || !state.location)
            return true

        if ((!props.isOpen || props?.cityUtility == 100) && !deliveryTimesSelected)
            return true

        return false
    }

    const highlightRequiredFields = () => {
        if (!state.user && (!firstname || !email || !phone)) {
            //Scroll to .checkout-delivery-address and highlight it
            document.getElementsByClassName("checkout-delivery-address")[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
            document.getElementsByClassName("checkout-delivery-address")[0].classList.add("invalid-highlight")
        }   
    }


    if (state.cart.length == 0) return (
        <Col md={12} className="text-center load-more">
            <Button variant="primary" type="button" disabled="">
                Košík je prázdný...
            </Button>
        </Col>
    )

    if (state.activeRestaurant) return (<>

        <MinimalOrderAdditionModal minimalOrderAddition={state.total.minimalOrderAddition} />

        <CartRecapitulationModal show={showCartRecapitulationModal} onHide={() => setShowCartRecapitulationModal(false)} submitNewOrder={props.submitNewOrder} selectedPaymentMethod={selectedPaymentMethod} noteToChef={noteToChef} noteToCourier={noteToCourier} name={state.user?.jmeno || ""} lastName={state.user?.prijmeni || ""} email={state.user?.login || ""} phone={state.user?.telefon || ""} address={state.location?.adresa?.string || ""} />

        <ExtraItems show={showExtraItems} handleAddItem={handleAddItemEshop} addedItem={addedItem} onClose={() => setShowExtraItems(false)} />
        <div className="checkout-parent">
            <AddAddressModal show={showAddressModal} onHide={hideAddressModal} />
            <div className="order-more">
                <span>Ještě si chci přiobjednat</span>
                {/* <Button onClick={()=>setDeliveryTimesSelected(deliveryTimes[0])}></Button> */}
                <Link to="objednat"><Button>Zpět na nabídku</Button></Link>
            </div>

            <div className="checkout-notes mt-4">

                <Row>
                    <Col md={6} className="checkout-cook-notes">
                        <Form.Control onChange={e => setnoteToChef(e.target.value)} type="text" placeholder="Napište poznámku kuchařovi" />
                        <Icofont icon="chef" className="input_img" />
                    </Col>
                    <Col md={6} className="checkout-courier-notes">
                        <Form.Control onChange={e => setnoteToCourier(e.target.value)} type="text" placeholder="Napište poznámku kurýrovi" />
                        <Icofont icon="scooter" className="input_img" />
                    </Col>
                </Row>

            </div>
            <div className="checkout-delivery-time mt-4">
                <div className="checkout-section-header">
                    <div className="checkout-section-number">
                        <span>1.</span>
                    </div>
                    <div className="checkout-section-title">
                        <span >Kdy chcete objednávku přivézt? {!props.isOpen ? props?.cityUtility == 100 ? "Maximální vytíženost - pouze objednávky na později" : "Zavřeno - pouze objednávky na později" : ""}</span>
                        {state.deliveryLaterFrom && <h6 className='t'><br /><Icofont icon="exclamation-circle" style={{ "fontSize": "1.3rem" }} /> Máte zvolené jídlo, které lze objednat pouze na další den!</h6>}
                    </div>
                </div>
                {!neverOpen && <Button variant="primary" className="collapse-button" onClick={() => setOpenSection1(!openSection1)} aria-controls="example-collapse-text" aria-expanded={openSection1}>{openSection1 ? "Hotovo" : "Změnit"}</Button>}

                {!openSection1 ? (
                    <div className="checkout-section-chosen">
                        <span className="checkout-section-chosen-title">Zvoleno:  </span><span>{neverOpen ? "Zavřeno, restaurace v nejbližší době neotevírá" : poledniMenuPolozkaNaPozdejiError ? "Položky, které máte v košíku nejdou objednat společně na jeden den." : deliveryTimesSelected ?
                            (deliveryDaysSelected ? deliveryDaysSelected : "Dnes") + " " + deliveryTimesSelected
                            :
                            props.isOpen ? "Přivézt co nejdříve" : "Vyberte čas doručení"}</span>
                    </div>

                ) : ""

                }
                {openSection1 ? (
                    <div className={`checkout-delivery-time-body`} >

                        <Row>
                            {!props.isOpen || state.deliveryLaterFrom || (state.isLunch && !state.activeRestaurant.allowLunchNow) ? ""
                                :
                                <Col md={6}>
                                    <Button className={`checkout-delivery-time-button mb-2 ${!(deliveryTimesSelected && deliveryDaysSelected) && "selected"}`} onClick={() => { setDeliveryTimesSelected(null) }}>Přivézt co nejdříve</Button>
                                </Col>
                            }
                            <Col md={!props.isOpen ? 12 : (state.isLunch && !state.activeRestaurant.allowLunchNow) ? 12 : 6} >
                                <div className="checkout-delivery-time-group ">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <Button onClick={() => setDeliveryTimesSelected(deliveryTimes[0])} className={`checkout-delivery-time-button ${(deliveryTimesSelected && deliveryDaysSelected) && "selected"}`}>Přivézt později</Button>
                                        </InputGroup.Prepend>
                                        <InputGroup.Prepend>
                                            <Form.Control value={deliveryDaysSelected || "Dnes"} className="checkout-delivery-time-select" as="select" custom onChange={(e) => setDeliveryDaysSelected(e.target.value)}>
                                                {deliveryDays.map(dt => (
                                                    <option>{dt}</option>
                                                ))}
                                            </Form.Control>
                                        </InputGroup.Prepend>
                                        <Form.Control value={deliveryTimesSelected || "Vyberte čas"} className="checkout-delivery-time-select" as="select" custom onChange={(e) => setDeliveryTimesSelected(e.target.value)}>
                                            {deliveryTimes.length > 0 ? deliveryTimes.map(dt => (
                                                <option>{dt}</option>
                                            )) :
                                                <option>Žádný čas k dispozici</option>
                                            }
                                        </Form.Control>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : ""}
            </div>


            {/* SEKCE 2 - ADRESA */}

            {state.user && userHasCompleInfo && !openSection2 ?
                <div className="checkout-delivery-address mt-4">
                    <div className="checkout-section-header">
                        <div className="checkout-section-number">
                            <span>2.</span>
                        </div>
                        <div className="checkout-section-title">
                            <span >Adresa pro doručení</span>
                        </div>
                    </div>

                    <Button className="collapse-button" onClick={() => { setOpenSection2(!openSection2) }} aria-controls="example-collapse-text" aria-expanded={openSection2}>Změnit</Button>

                    <div className="checkout-section-chosen">
                        {state.user ?
                            <><span className="checkout-section-chosen-title">Zvoleno: </span><span>{state.user.jmeno + " " + state.user.prijmeni}, email: {state.user.login}, tel: {state.user.telefon}{state.location && ", " + state.location.adresa.string}</span></>
                            :
                            <span className="checkout-section-chosen-title">Zvoleno: </span>
                        }
                    </div>

                    {state.location && !openSection2 ?
                        ""
                        :
                        <>
                            <br />
                            <h6 className="">Moje Doručovací adresa:</h6>
                            <AddressSearch />
                        </>
                    }

                </div>
                /* Not logged in: */
                :
                <OrderForm searchError={searchError} formHook={formHook} checkout={true} setFirstname={setFirstname} setLastname={setLastname} setPhone={setPhone} beginCheckoutSended={beginCheckoutSended} setEmail={setEmail} totalPrice={props.totalPrice} />

            }

            <div className="checkout-payment mt-4 mb-4">
                <div className="checkout-section-header">
                    <div className="checkout-section-number">
                        <span>3.</span>
                    </div>
                    <div className="checkout-section-title">
                        <span >Platba</span>
                    </div>
                </div>




                <Button className="collapse-button" onClick={() => setOpenSection3(!openSection3)} aria-controls="example-collapse-text" aria-expanded={openSection3}>{openSection3 ? "Hotovo" : "Změnit"}</Button>

                {!openSection3 ? (
                    <div className="checkout-section-chosen">
                        <span className="checkout-section-chosen-title">Zvoleno: </span><span>{paymentMethods[selectedPaymentMethod]}</span>
                    </div>

                ) : ""}

                {openSection3 ? (
                    <div className={`checkout-payment-body`} >

                        <Row>
                            <Col lg={4} md={6} sm={6} onClick={() => setSelectedPaymentMethod("cash")}>
                                <PaymentMethod title="Hotově při předání" value="cash" iconName="wallet" selected={selectedPaymentMethod}></PaymentMethod>
                            </Col>
                            {state.activeRestaurant?.kuryr_platba_kartou == "a" &&
                            <Col lg={4} md={6} sm={6} onClick={() => setSelectedPaymentMethod("courier-card")}>
                                <PaymentMethod title="Kartou u kurýra" value="courier-card" iconName="wallet" selected={selectedPaymentMethod}></PaymentMethod>
                            </Col>
                            }
                            {state.activeRestaurant?.isCashles == "1" &&
                                (disableCardPayments ?
                                    <Col lg={4} md={6} sm={6} >
                                        <PaymentMethod disabled title="Dočasně nedostupný - Kartou ihned" value="credit-card" iconName="credit-card" selected={selectedPaymentMethod} ></PaymentMethod>
                                    </Col>
                                    :
                                    <>
                                    <Col lg={4} md={6} sm={6} onClick={() => { setSelectedPaymentMethod("credit-card"); setToggleCredits(false) }}>
                                        <PaymentMethod title="Kartou on-line ihned" value="credit-card" iconName="credit-card" selected={selectedPaymentMethod} ></PaymentMethod>
                                    </Col>
                                    <Col lg={4} md={6} sm={6} onClick={() => { setSelectedPaymentMethod("food-credit-card"); setToggleCredits(false) }}>
                                    <PaymentMethod title="Stravenkovou kartou on-line ihned
(Edenred, Upcz, Naše stravenka)" value="food-credit-card" iconName="credit-card" selected={selectedPaymentMethod} ></PaymentMethod>
                                    </Col>  
                                    </>
                                    )
                            }
                            {state.activeRestaurant?.isVoucher == "1" &&
                                <Col lg={4} md={6} sm={6} onClick={() => setSelectedPaymentMethod("meal-voucher")}>
                                    <PaymentMethod title="Stravenkami" value="meal-voucher" iconName="ticket" selected={selectedPaymentMethod}></PaymentMethod>
                                </Col>
                            }
                            {/* <Col lg={4} md={6} sm={6} onClick={()=>setSelectedPaymentMethod("credit-card-driver")}>
                                <PaymentMethod title="Kartou u řidiče" value="credit-card-driver" iconName="pay" selected={selectedPaymentMethod}></PaymentMethod>
                            </Col> */}
                            {state.activeRestaurant?.isCashles == "1" &&
                                <Col lg={4} md={6} sm={6} onClick={() => setSelectedPaymentMethod("twisto")}>
                                    <PaymentMethod title="Až po jídle" value="twisto" iconName="pay" customIcon={true} selected={true} imagePath={require("../../resources/twisto.png")} selected={selectedPaymentMethod}></PaymentMethod>
                                </Col>
                            }
                            {/* <Col lg={4} md={6} sm={6} onClick={()=>setSelectedPaymentMethod("mallpay")}>
                                <PaymentMethod title="Až po jídle" value="mallpay" iconName="pay" customIcon={true} imagePath={require("../../resources/mallPay.png")} selected={selectedPaymentMethod}></PaymentMethod>
                            </Col> */}
                        </Row>
                        <hr></hr>
                        <Row>
                            {(toggleCredits || selectedPaymentMethod != "credit-card") && state.user ?
                                <>
                                    <Col lg={8} md={12}>
                                        <div className="courier-tip">
                                            <Row>
                                                <Col md={4} className="text-center my-auto">
                                                    <Icofont className="courier-tip-icon" icon="coins" />
                                                    <span className="courier-tip-text">Použít kredity</span>
                                                </Col>
                                                <Col md={8}>

                                                    {credits?.credits_jpn && <><span className="pr-4" style={{ "whiteSpace": "pre" }}>Kredity jídlopodnos: <span className="red-text">{credits?.credits_jpn || "Načítání..."}</span></span><br></br></>}
                                                    {credits?.credits_rest ? <span className="" style={{ "whiteSpace": "pre" }}>Kredity restaurace: <span className="red-text">{credits?.credits_rest || "Načítání..."}</span></span> : ""}



                                                    <InputGroup value={creditsUsed} className="mb-3 courier-tip-input">
                                                        <FormControl type="number" onKeyDown={blockInvalidChar} style={{ "textAlign": "right", "fontSize": "15px" }} onChange={(e) => handleSetCredits(e.target.value)} value={creditsUsed > 0 ? creditsUsed : null}
                                                            placeholder="Zadejte počet"
                                                        />
                                                        <InputGroup.Append>
                                                            <Button disabled className="courier-tip-ok">Kč</Button>
                                                        </InputGroup.Append>

                                                    </InputGroup>
                                                    {state.totalAddition.creditsUsed > 0 ? <span className="text-success w-100 d-block mb-2"><Icofont size="1" icon="check" className="ml-1" /> Použito {creditsUsed} kreditů</span>:""}
                                                    <Button onClick={useCredits}>
                                                        Použít tyto kredity
                                                    </Button>
                                                    {Number(creditsUsed) > Number(credits?.credits_jpn) + Number(credits?.credits_rest) ? <h5 className="homepage-search-description text-danger mt-3">{"Nedostatek kreditů"} </h5> : ""}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    {selectedPaymentMethod == "credit-card" &&
                                        <Col lg={4} md={6} sm={6} onClick={() => setToggleCredits(false)}>
                                            <PaymentMethod title={`Spropitné kurýrovi `} iconName="scooter" ></PaymentMethod>
                                        </Col>
                                    }
                                </>
                                :
                                <>
                                    <Col lg={4} md={6} sm={6} onClick={() => setToggleCredits(true)}>
                                        <PaymentMethod title={`Použít kredity ${!state.user ? "(Vyžaduje přihlášení)" : ""}`} iconName="coins" ></PaymentMethod>
                                    </Col>
                                    {selectedPaymentMethod == "credit-card" &&
                                        <Col lg={8} md={12}>

                                            <div className="courier-tip">
                                                <Row>
                                                    <Col md={4} className="text-center">
                                                        <Icofont className="courier-tip-icon" icon="scooter" />
                                                        <span className="courier-tip-text">Spropitné kurýrovi</span>
                                                    </Col>
                                                    <Col md={8}>

                                                        <ButtonGroup className="courier-tip-buttons">
                                                            <Button onClick={() => setTipCourier(10)} className="courier-tip-button">10Kč</Button>
                                                            <Button onClick={() => setTipCourier(20)} className="courier-tip-button">20Kč</Button>
                                                            <Button onClick={() => setTipCourier(30)} className="courier-tip-button">30Kč</Button>
                                                        </ButtonGroup>

                                                        <InputGroup className="mb-3 courier-tip-input">
                                                            <FormControl type="number" onKeyDown={blockInvalidChar} style={{ "textAlign": "right", "fontSize": "15px" }} onChange={(e) => setTipCourier(e.target.value)} value={tipCourier > 0 ? tipCourier : 0}
                                                                placeholder="Částka"
                                                            />
                                                            <InputGroup.Append>
                                                                <Button disabled className="courier-tip-ok">Kč</Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Col>
                                    }
                                </>
                            }
                        </Row>


                    </div>



                ) : ""}
                {/* {<Button className="w-100" onClick={()=>{}} className="mt-4 order-cart-submit">Dokončit objednávku</Button>} */}
            </div>

            {eshop && Object.keys(eshop).length > 0 && <div className="checkout-extra-drink checkout-payment mt-4 mb-4">
                {/* <span className="checkout-extra-drink-title">K objednávce si ještě vezmu</span> */}
                <div className="checkout-section-header">
                    <div className="checkout-section-number">
                        <span>4.</span>
                    </div>
                    <div className="checkout-section-title">
                        <span >K objednávce si ještě vezmu</span>
                    </div>
                </div>
                {/* <ExtraDrinks/> */}
                <div className={!eshopExpanded && "box-bottom-soft"} style={{ "overflow": "hidden", "maxHeight": eshopExpanded ? "unset" : "50vh" }}>
                    {eshop && Object.keys(eshop).map((category, index) => (
                        <>
                            <Row key={index} id={category}>
                                <h6 className="mt-3 col-md-12 order-big-card-category-title">{category}</h6>
                                <Col md={12}>
                                    <div className=" rounded mb-2">
                                        {eshop[category].map(item => (
                                            //<QuickBite key={item.polozka_id} item={item} itemClass="menu-list" /* onClick={displayExtraItems} */  />
                                            <Row className="p-1 mt-1" style={{ "borderBottom": "1px solid rgb(224 227 231)" }}>
                                                <Col md={9} sm={12} style={{ "alignSelf": "center", "display": "flex" }}>
                                                    <div style={{ "height": "100%" }}>
                                                        <Image style={{ "height": "45px", "width": "50px" }} /* onClick={()=>props.showModal(props.item.polozka_id)} */ className={"mr-3 "} src={`https://www.jidlopodnos.cz/data/jidla/small/${item.polozka_id}.png`} onError={(e) => { e.target.onerror = null; e.target.src = ""; e.target.style.display = "none" }} alt="" />
                                                    </div>
                                                    <div>
                                                        {item.nazev}
                                                        <span className="order-big-card-item-price">{item.cena} Kč</span>
                                                    </div>
                                                </Col>
                                                <Col md={3} sm={12} style={{ "alignSelf": "center" }}>
                                                    <div className='add-to-cart-parent'>
                                                        <Button className='add-to-cart-button' onClick={() => displayExtraItems(item)} size="sm">Přidat do košíku</Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        </>))}
                </div>
                {!eshopExpanded &&
                    <Button className='add-to-cart-button mt-3' onClick={() => setEshopExpanded(true)}>Ukázat více <Icofont icon="rounded-down"></Icofont> </Button>
                }
            </div>}
            <div className="w-100 order-cart">
                {deliveryTimesSelected &&
                    <p className="text-danger" style={{ "fontSize": "1.5rem" }}><Icofont icon="exclamation-circle" style={{ "fontSize": "1.5rem" }} /> Objednáváte na {deliveryDaysSelected == "Dnes" || deliveryDaysSelected == null ? moment().format('D.M.') : deliveryDaysSelected} {state.isLunch ? deliveryTimesSelected : deliveryTimesSelected}</p>
                }
                <Form.Label style={{ "fontSize": "1.2rem" }} >
                    <Form.Check onChange={(e)=>setMarketingAgreement(e.target.checked)} style={{ "transform": "scale(1.3)" }} required type="checkbox" inline name={"newsletter"} />
                    Souhlasím se zasíláním novinek
                </Form.Label>
                <div style={{ position: 'relative' }}>
                    <Button 
                        style={{ "fontSize": "150%" }} 
                        size="lg" 
                        onClick={() => setShowCartRecapitulationModal(true)} 
                        disabled={disableSubmit()} 
                        className="mt-4 w-100"
                    >
                        REKAPITULACE OBJEDNÁVKY
                    </Button>
                    {disableSubmit() && (
                        <div 
                            style={{ 
                                position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                right: 0, 
                                bottom: 0, 
                                cursor: 'not-allowed' 
                            }} 
                            onClick={highlightRequiredFields}
                        />
                    )}
                </div>
            </div>



        </div>
    </>)

    return (
        <Col md={12} className="text-center load-more">
            <Button variant="primary" type="button" disabled="">
                <Spinner animation="grow" size="sm" className='mr-1' />
                Načítání...
            </Button>
        </Col>
    )
}

const checkLsValidity = async (data) => {
    return
    console.log("Checking validity for data: ", data)
    if (!(data.cart?.length || data.activeRestaurant || data.location)) {
        console.log("No data, returning as valid")
        return { cart: null, activeRestaurant: null, location: null }
    }


    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: '/api/user/checkLocalStorageValidity',
            data
        }).then(response => {
            console.log("Response: /api/user/checkLocalStorageValidity", response)
            if (response.data && response.data.hasOwnProperty("activeRestaurant") && response.data.hasOwnProperty("location") && response.data.hasOwnProperty("cart")) {
                resolve(response.data)
            } else {
                console.log("checkLsValidity Error")
                resolve(data)
            }
        })
    })
}

export default NewCheckout


function round_time(date, duration, method = "ceil") {
    duration = moment.duration(duration, "minutes")
    return moment(Math[method]((+date) / (+duration)) * (+duration));
}



//start: moment(), end: moment(), step: int
function generateTimeSeries(start, end, step, isTimeInverval = false) {
    console.log("TIME SERIES start: ", start.format("HH:mm"))
    console.log("TIME SERIES end: ", end.format("HH:mm"))
    const interval = start
    const available_intervals = [];

    while (interval <= end) {
        console.log(interval.format("HH:mm"))
        if (isTimeInverval) {
            available_intervals.push(interval.format("HH:mm") + " - " + interval.add(step, "minutes").format("HH:mm"));
        } else {
            available_intervals.push(interval.format("HH:mm"));
            interval.add(step, "minutes")
        }

    }

    return available_intervals;
}
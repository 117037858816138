import React,{useState,useEffect,useContext, useCallback} from 'react'
import { Container, Row, Col, Modal, Button} from 'react-bootstrap'
import CardItem from '../common/CardItem';
import { useParams } from "react-router-dom";
import moment from 'moment';
import useFetchARefresh from '../../useFetchAutoRefresh';
import {AppContext} from '../../context.js'
import ScrollButton from '../custom/ScrollButton';
import Icofont from 'react-icofont';
import { filter } from 'lodash';
import FilterRest from './FilterRest'
import useWindowSize from '../../useWindowsize';
import { useHistory } from 'react-router-dom';

const AllRestaurants = (props) => {

	let { id } = useParams();

	const {state, dispatch} = useContext(AppContext)

	const {restaurants} = props

	const [closedRestaurants, setClosedRestaurants] = useState([])

	const {data: cityUtility} = useFetchARefresh('/api/menu/cityUtility/'+id) 

	const [hideMaxUtil, setHideMaxUtil] = useState(false)

	const [hideMultiCity, setHideMultiCity] = useState(false)

	const [restaurantCheckboxFilter, setRestaurantCheckboxFilter] = useState([])

	const size = useWindowSize();	
	const history = useHistory();


	const alertText = (delivery_from,delivery_to,open_today)=>{
		if(open_today == "zavřeno" || !delivery_from || !delivery_to) return "Dnes zavřeno"
		let deliveryFrom = moment(delivery_from,"HH:mm")._i
		let deliveryTo = moment(delivery_to,"HH:mm")._i
		let now = moment().format("HH:mm")
		if(now<deliveryFrom) return `Restaurace rozváží až v ${delivery_from.slice(0,-3)}`
		if(now>deliveryTo) return "Aktuálně zavřeno"
		//console.log(now>deliveryTo)
		return false
		//return `Restaurace rozváží až v ${props.delivery_from && props.delivery_from.slice(0,-3)}`
	}

	const switchLocation = (city) => {
        console.log(city)
        let mesto_url = city.mesto.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-');
        dispatch({ type: 'LOADLOCATION', payload: { source:"Allrestaurants",...city, mesto_url: mesto_url, adresa: state.location.adresa,polygonRestaurants:state.location.polygonRestaurants, multiLocation: state.location.multiLocation,multiLocationWarningShown:true } })
		history.push(`/rozvoz-jidel/${mesto_url}`);
    }

	const filter = useCallback((rest)=>{
		let categoryFilter = state.restaurantFilter ? rest?.rest_categories?.split(",")?.join().includes(state.restaurantFilter||"") : true
		let checkboxFilter = state.checkbox_restaurantFilter?.length>0?
			state.checkbox_restaurantFilter.filter(filter=>rest[filter]==0).length==0 ? true : false
			:
			true
		let nameFilter = rest?.name?.toLowerCase().includes(state.name_restaurantFilter?.toLowerCase()||"")
		return categoryFilter && checkboxFilter && nameFilter
	},[state.restaurantFilter,state.checkbox_restaurantFilter,state.name_restaurantFilter])



    return (
        <Container fluid style={{"max-width":"1292px","width":size.width > 767.98 ?"80%":"100%"}} className="all-restaurants">
			<ScrollButton/>
			<Modal className="extra-items" show={cityUtility?.cityUtility==100 && !hideMaxUtil}>
				<Modal.Body>
					<Button className="extra-items-close-button" onClick={() => { setHideMaxUtil(true) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>

					<h5 className="extra-items-title">Upozornění</h5>
					<hr></hr>
					<p className="">Všichni řidiči momentálně vyřizují jiné objednávky. Objednat si můžete pouze na později.</p>

				</Modal.Body>
			</Modal>
			<Modal className="extra-items" show={!hideMultiCity && state?.location?.multiLocation?.length>1 && !state.location.multiLocationWarningShown}>
				<Modal.Body>
					<Button className="extra-items-close-button" onClick={() => { setHideMultiCity(true) }}><ion-icon className="extra-items-close-button-icon" name="close" /></Button>

					<h3 className="extra-items-title">Objednat si můžete z více měst.</h3>
					<hr></hr>
					<p className="">Zvolte město pro zobrazení restaurací</p>
					<Row >
						<Col md={"1"}/>
						{state?.location?.multiLocation?.map(city=>(
							<Button onClick={()=>{switchLocation(city)}} className="w-100 mt-1 mr-3 ml-3" variant="success">{city.mesto}</Button>
						))}
						<Col md={"1"}/>
					</Row>
					
				</Modal.Body>
			</Modal>
            <h3 id="all-rest-head" style={{"marginBottom":"0"}}>Všechny restaurace {state.restaurantFilter && <Button onClick={()=>dispatch({type: 'REMOVE_RESTAURANT_FILTER'})}>{state.restaurantFilter} <Icofont icon="close"/></Button>}</h3> 

			<FilterRest multiCity={state?.location?.multiLocation}></FilterRest>

            <Row>

					{restaurants ? (restaurants.filter(rest=>filter(rest)).map((restaurant,index) => (
						!alertText(restaurant.delivery_from,restaurant.delivery_to,restaurant.open_today) &&
						<Col key={index} md={3} className="columns">
							<CardItem
								key= {restaurant.id}
								id={restaurant.id}
								vlastni={restaurant.vlastni}
								alertText={alertText(restaurant.delivery_from,restaurant.delivery_to,restaurant.open_today)}
								isFav={restaurant.isFav}
								title= {restaurant.name}
								subTitle={restaurant.rest_categories?.split(",")?.splice(0,3)?.join()+(restaurant.rest_categories?.split(",")?.length>3?"...":"")}
								cityUtility={cityUtility}
								alert_badge={restaurant.alert_badge}
								alert_badge_description={restaurant.alert_badge_description}
								delivery_from={restaurant.delivery_from}
								delivery_to={restaurant.delivery_to}
								imageAlt={restaurant.imageAlt}
								image={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${restaurant.id}.jpg`}
								imageClass={restaurant.imageClass}
								linkUrl={`${id}/${restaurant.rest_url}/objednat`}
								time={`${restaurant.delivery_tmin+(Number(state?.location?.cas || 0))}-${restaurant.delivery_tmax+(Number(state?.location?.cas || 0))}min`}
								favIcoIconColor={restaurant.favIcoIconColor}
								rating={restaurant.rating}
								deliveryPrice = {restaurant.delivery_price}
								stravenky = {restaurant.isVoucher}
								creditCard = {restaurant.isCashles}
								bonusProgram = {restaurant.isBonus}
								freeDelivery = {restaurant.isFreeDelivery}
								delivery_free = {restaurant.delivery_free}
								canBookTable = {true}
							/>
						</Col>
						
					))):""}
					{restaurants ? (restaurants.filter(rest=>filter(rest)).map((restaurant) => (
						alertText(restaurant.delivery_from,restaurant.delivery_to,restaurant.open_today) &&
						<Col key={restaurant.id} md={3} className="columns">
							<CardItem
								key= {restaurant.id}
								id={restaurant.id}
								vlastni={restaurant.vlastni}
								alertText={alertText(restaurant.delivery_from,restaurant.delivery_to,restaurant.open_today)}
								isFav={restaurant.isFav}
								title= {restaurant.name}
								subTitle={restaurant.rest_categories?.split(",")?.splice(0,3)?.join()}
								alert_badge={restaurant.alert_badge}
								alert_badge_description={restaurant.alert_badge_description}
								delivery_from={restaurant.delivery_from}
								delivery_to={restaurant.delivery_to}
								imageAlt={restaurant.imageAlt}
								image={`https://www.jidlopodnos.cz/data/restaurace/mkartan/${restaurant.id}.jpg`}
								imageClass={restaurant.imageClass}
								linkUrl={`${id}/${restaurant.rest_url}/objednat`}
								time={`${restaurant.delivery_tmin}-${restaurant.delivery_tmax}min`}
								favIcoIconColor={restaurant.favIcoIconColor}
								rating={restaurant.rating}
								deliveryPrice = {restaurant.delivery_price}
								stravenky = {restaurant.isVoucher}
								creditCard = {restaurant.isCashles}
								bonusProgram = {restaurant.isBonus}
								freeDelivery = {restaurant.isFreeDelivery}
								delivery_free = {restaurant.delivery_free}
								canBookTable = {true}
							/>
						</Col>
						
					))):""}
            </Row>
        </Container>
    )
}

export default AllRestaurants

